import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { getStringFromChildren } from "@src/deprecatedDesignSystem/components/DetailPage/utils";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { css, StyleSheet } from "aphrodite";
import { HTMLAttributes, ReactNode, useMemo } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";

type Props = {
  icon: DeprecatedIconType;
  onClick: () => void;
  children: ReactNode;
  destroy?: boolean;
  disabled?: boolean;
  disabledReason?: string;
};

export default function DetailHeaderOverflowItem({
  icon,
  onClick,
  children,
  destroy,
  disabled,
  disabledReason,
  ...restProps
}: Props & HTMLAttributes<HTMLDivElement>): JSX.Element {
  const text = useMemo(() => getStringFromChildren(children), [children]);
  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <div
          className={css(
            styles.container,
            disabled && styles.disabled,
            !destroy && styles.defaultHover,
            destroy && styles.destroyHover,
          )}
          onClick={disabled ? undefined : onClick}
          {...restProps}
        >
          <DeprecatedIcon
            type={icon}
            color={destroy ? deprecatedTones.red10 : deprecatedTones.gray10}
            style={{ marginRight: 8, marginLeft: 4 }}
          />
          <Text
            type="P2"
            color={destroy ? deprecatedTones.red10 : deprecatedTones.black}
            style={{ userSelect: "none" }}
          >
            {text}
          </Text>
        </div>
      </TooltipTrigger>
      {disabled && disabledReason && (
        <TooltipContent align="center" className="z-[1000]" sideOffset={4}>
          <div>{disabledReason}</div>
        </TooltipContent>
      )}
    </Tooltip>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 36,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    zIndex: 1,
    paddingRight: 12,
    borderRadius: 10,
    textDecoration: "none",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
  },
  defaultHover: {
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  destroyHover: {
    ":hover": {
      backgroundColor: deprecatedTones.red2,
    },
  },
  disabled: {
    backgroundColor: deprecatedTones.gray5Alpha,
    borderRadius: 0,
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
    cursor: "not-allowed",
  },
});
