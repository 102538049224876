import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { EmployeeListItemFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RolesListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RolesListQuery = { __typename?: 'Query', Roles: { __typename?: 'Roles', totalCount: number, objects: Array<{ __typename?: 'Role', id: number, name: string, userCount: number }> } };

export type LocationGroupFragment = { __typename?: 'LocationGroup', id: string, name: string, userCount: number, locationMemberships: Array<{ __typename?: 'LocationGroupMembership', id: string, location: { __typename?: 'Location', id: number } }> };

export type DeleteLocationsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type DeleteLocationsMutation = { __typename?: 'Mutation', deleteLocations: { __typename?: 'BaseMutationResult', success: boolean } };

export type DeleteRolesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type DeleteRolesMutation = { __typename?: 'Mutation', deleteRoles: { __typename?: 'BaseMutationResult', success: boolean } };

export type DeleteLocationGroupsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DeleteLocationGroupsMutation = { __typename?: 'Mutation', deleteLocationGroups: { __typename?: 'BaseMutationResult', success: boolean } };

export type DeleteRoleGroupsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DeleteRoleGroupsMutation = { __typename?: 'Mutation', deleteRoleGroups: { __typename?: 'BaseMutationResult', success: boolean } };

export type DeleteLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation: { __typename?: 'LocationMutationResult', success: boolean } };

export type DeleteRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'RoleMutationResult', success: boolean } };

export type GetPeopleQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type GetPeopleQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, createdAt: string, name: string, firstName: string, lastName: string, email?: string | null, phoneNumber?: string | null, language: Language, hasUsedApp: boolean, deactivatedAt?: string | null, deactivatedReason?: Types.DeactivationReason | null, userType: Types.UserType, lastActivityAt?: string | null, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, deactivatedBy?: { __typename?: 'Employee', id: number, name: string } | null, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, firstName: string, lastName: string, startDate?: string | null } | null }> } };

export const LocationGroupFragmentDoc = gql`
    fragment LocationGroup on LocationGroup {
  id
  name
  userCount
  locationMemberships {
    id
    location {
      id
    }
  }
}
    `;
export const RolesListDocument = gql`
    query RolesList {
  Roles {
    objects {
      id
      name
      userCount
    }
    totalCount
  }
}
    `;

/**
 * __useRolesListQuery__
 *
 * To run a query within a React component, call `useRolesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesListQuery(baseOptions?: Apollo.QueryHookOptions<RolesListQuery, RolesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesListQuery, RolesListQueryVariables>(RolesListDocument, options);
      }
export function useRolesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesListQuery, RolesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesListQuery, RolesListQueryVariables>(RolesListDocument, options);
        }
export type RolesListQueryHookResult = ReturnType<typeof useRolesListQuery>;
export type RolesListLazyQueryHookResult = ReturnType<typeof useRolesListLazyQuery>;
export type RolesListQueryResult = Apollo.QueryResult<RolesListQuery, RolesListQueryVariables>;
export const DeleteLocationsDocument = gql`
    mutation DeleteLocations($ids: [Int!]!) {
  deleteLocations(ids: $ids) {
    success
  }
}
    `;
export type DeleteLocationsMutationFn = Apollo.MutationFunction<DeleteLocationsMutation, DeleteLocationsMutationVariables>;

/**
 * __useDeleteLocationsMutation__
 *
 * To run a mutation, you first call `useDeleteLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationsMutation, { data, loading, error }] = useDeleteLocationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteLocationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationsMutation, DeleteLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationsMutation, DeleteLocationsMutationVariables>(DeleteLocationsDocument, options);
      }
export type DeleteLocationsMutationHookResult = ReturnType<typeof useDeleteLocationsMutation>;
export type DeleteLocationsMutationResult = Apollo.MutationResult<DeleteLocationsMutation>;
export type DeleteLocationsMutationOptions = Apollo.BaseMutationOptions<DeleteLocationsMutation, DeleteLocationsMutationVariables>;
export const DeleteRolesDocument = gql`
    mutation DeleteRoles($ids: [Int!]!) {
  deleteRoles(ids: $ids) {
    success
  }
}
    `;
export type DeleteRolesMutationFn = Apollo.MutationFunction<DeleteRolesMutation, DeleteRolesMutationVariables>;

/**
 * __useDeleteRolesMutation__
 *
 * To run a mutation, you first call `useDeleteRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolesMutation, { data, loading, error }] = useDeleteRolesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteRolesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRolesMutation, DeleteRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRolesMutation, DeleteRolesMutationVariables>(DeleteRolesDocument, options);
      }
export type DeleteRolesMutationHookResult = ReturnType<typeof useDeleteRolesMutation>;
export type DeleteRolesMutationResult = Apollo.MutationResult<DeleteRolesMutation>;
export type DeleteRolesMutationOptions = Apollo.BaseMutationOptions<DeleteRolesMutation, DeleteRolesMutationVariables>;
export const DeleteLocationGroupsDocument = gql`
    mutation DeleteLocationGroups($ids: [String!]!) {
  deleteLocationGroups(ids: $ids) {
    success
  }
}
    `;
export type DeleteLocationGroupsMutationFn = Apollo.MutationFunction<DeleteLocationGroupsMutation, DeleteLocationGroupsMutationVariables>;

/**
 * __useDeleteLocationGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteLocationGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationGroupsMutation, { data, loading, error }] = useDeleteLocationGroupsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteLocationGroupsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationGroupsMutation, DeleteLocationGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationGroupsMutation, DeleteLocationGroupsMutationVariables>(DeleteLocationGroupsDocument, options);
      }
export type DeleteLocationGroupsMutationHookResult = ReturnType<typeof useDeleteLocationGroupsMutation>;
export type DeleteLocationGroupsMutationResult = Apollo.MutationResult<DeleteLocationGroupsMutation>;
export type DeleteLocationGroupsMutationOptions = Apollo.BaseMutationOptions<DeleteLocationGroupsMutation, DeleteLocationGroupsMutationVariables>;
export const DeleteRoleGroupsDocument = gql`
    mutation DeleteRoleGroups($ids: [String!]!) {
  deleteRoleGroups(ids: $ids) {
    success
  }
}
    `;
export type DeleteRoleGroupsMutationFn = Apollo.MutationFunction<DeleteRoleGroupsMutation, DeleteRoleGroupsMutationVariables>;

/**
 * __useDeleteRoleGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteRoleGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleGroupsMutation, { data, loading, error }] = useDeleteRoleGroupsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteRoleGroupsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleGroupsMutation, DeleteRoleGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleGroupsMutation, DeleteRoleGroupsMutationVariables>(DeleteRoleGroupsDocument, options);
      }
export type DeleteRoleGroupsMutationHookResult = ReturnType<typeof useDeleteRoleGroupsMutation>;
export type DeleteRoleGroupsMutationResult = Apollo.MutationResult<DeleteRoleGroupsMutation>;
export type DeleteRoleGroupsMutationOptions = Apollo.BaseMutationOptions<DeleteRoleGroupsMutation, DeleteRoleGroupsMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: Int!) {
  deleteLocation(id: $id) {
    success
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: Int!) {
  deleteRole(id: $id) {
    success
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetPeopleDocument = gql`
    query GetPeople($input: GetPeopleInput!, $pagination: PaginationInput) {
  People(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...EmployeeListItem
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPeopleQuery(baseOptions: Apollo.QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
      }
export function useGetPeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
        }
export type GetPeopleQueryHookResult = ReturnType<typeof useGetPeopleQuery>;
export type GetPeopleLazyQueryHookResult = ReturnType<typeof useGetPeopleLazyQuery>;
export type GetPeopleQueryResult = Apollo.QueryResult<GetPeopleQuery, GetPeopleQueryVariables>;