import { useRemoveEmployeesMutation } from "@components/modals/DeactivatePeopleModal.generated";
import Modal from "@components/modals/Modal";
import Spacer from "@components/ui/Spacer";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import { GraphqlOperations } from "@src/types.generated";
import { pluralize } from "@utils/strings";
import { StyleSheet, css } from "aphrodite";
import { gql } from "graphql-tag";
import { FC } from "react";

type Props = {
  employeeIds: number[];
  onSuccess?: () => void;
};

const DeactivatePeopleModal: FC<Props> = ({ employeeIds, onSuccess }) => {
  const { userActionPermissions } = useActionPermissions();
  const { closeModal } = useModal();
  const { trackEvent } = useTracking();
  const { addErrorToast, addToast } = useToast();
  const [removeEmployees, { loading }] = useRemoveEmployeesMutation({
    refetchQueries: [
      GraphqlOperations.Query.PersonDetailDetails,
      GraphqlOperations.Query.PersonDetailHeader,
      GraphqlOperations.Query.GetPeople,
      GraphqlOperations.Query.LocationGroupHeader_PeopleCount,
      GraphqlOperations.Query.RoleDetail,
    ],
    onCompleted: (data) => {
      if (data.removeEmployees.success) {
        closeModal();
        trackEvent("removed_employees", {
          employeeIds,
        });
        onSuccess && onSuccess();
        addToast({
          iconType: "user",
          message: `${employeeIds.length} ${pluralize(
            "employee",
            employeeIds.length,
          )} ${employeeIds.length === 1 ? "has" : "have"} been deactivated`,
        });
      } else {
        addErrorToast({
          ...data.removeEmployees.error,
          callsite: "remove_employees",
        });
      }
    },
    onError: (error) => {
      addErrorToast({ ...error, callsite: "remove_employees" });
    },
    variables: {
      ids: employeeIds,
    },
  });

  return (
    <Modal
      title={
        employeeIds.length === 1 ? "Deactivate people" : "Deactivate people"
      }
      confirmButtonProps={{
        copy: "Deactivate",
        disabled: !userActionPermissions?.deactivateTrainees,
        onClick: removeEmployees,
        loading,
        alert: true,
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <div className={css(styles.body)}>
        {!userActionPermissions ? (
          <Spinner centered />
        ) : !userActionPermissions?.deactivateTrainees ? (
          <Text type="P2" multiline>
            You do not have permission to deactivate people
          </Text>
        ) : (
          <>
            <Text type="P2" multiline>
              Are you sure you want to deactivate {employeeIds.length}{" "}
              {pluralize("employee", employeeIds.length)}?
            </Text>
            <Spacer size={16} />
            <div className={css(styles.bullet)}>
              • They will no longer receive training
            </div>
            <div className={css(styles.bullet)}>
              • They will no longer appear in the dashboard
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

gql`
  mutation RemoveEmployees($ids: [Int!]!) {
    removeEmployees(ids: $ids) {
      success
      error {
        code
      }
    }
  }
`;

export default DeactivatePeopleModal;

const styles = StyleSheet.create({
  body: {
    fontSize: "14px",
    padding: "32px 24px",
  },
  bullet: {
    marginBottom: "4px",
    lineHeight: "18px",
    color: deprecatedColors.outline,
  },
});
