import usePeopleFilterState, {
  EMPTY_PEOPLE_FILTERS,
  PeopleFilterState,
} from "./usePeopleFilterState";

import { FC } from "react";
import FilterComponent from "../core/FilterDropdownComponent";

interface PeopleFiltersProps {
  excludePeopleFilterTypes?: (keyof PeopleFilterState)[];
}

const PeopleFiltersDropdown: FC<PeopleFiltersProps> = ({
  excludePeopleFilterTypes = [],
}: PeopleFiltersProps) => {
  const { options } = usePeopleFilterState({
    excludeFilterTypes: excludePeopleFilterTypes,
  });
  return (
    <FilterComponent
      filterOptions={options}
      onClearFilterValues={[EMPTY_PEOPLE_FILTERS]}
    />
  );
};

export default PeopleFiltersDropdown;
