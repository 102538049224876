import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Checkbox from "@src/deprecatedDesignSystem/components/Checkbox";
import CircleDivider from "@src/deprecatedDesignSystem/components/CircleDivider";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import IconAvatar from "@src/deprecatedDesignSystem/components/IconAvatar";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import Text, { TextProps, TextType } from "@ui/text";
import {
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet, CSSProperties } from "aphrodite";
import {
  FC,
  MouseEvent,
  ReactNode,
  CSSProperties as ReactCSSProperties,
} from "react";
import { OrgLogo } from "@src/components/layout/topNav/OrgLogo";
import useBranding from "@src/hooks/useBranding";
import useUser from "@src/hooks/useUser";

type Subtitle = {
  subtitle1: React.ReactNode;
  subtitle2?: React.ReactNode;
};

type ShowSelectOnHover = {
  isSelected: boolean;
  onSelectClick: (value: boolean, e: MouseEvent<HTMLElement>) => void;
};

type Person = {
  name?: string | null | undefined;
  id: number;
};

export type AvatarType = "Person" | "Org" | "Icon" | "Content";

type Props = {
  size: "32px" | "40px";
  avatarType?: AvatarType;
  avatar?: ReactNode;
  title: string | ReactNode;
  titleType?: TextType;
  titleProps?: Omit<TextProps, "children">;
  truncateTitle?: CSSProperties["width"];
  isHovered?: boolean;
  showSelectOnHover?: ShowSelectOnHover;
  subtitle?: Subtitle;
  person?: Person;
  icon?: DeprecatedIconType;
  onClick?: () => void;
  imageUrl?: string | null;
  contentType?: "Course" | "Module" | "Skill" | "Resource" | "PremiumPath";
  contentId?: number | string;
  color?: DeprecatedToneName;
  styleDeclaration?: StyleDeclaration;
  style?: ReactCSSProperties;
};

const AvatarTitleSubtitle: FC<Props> = ({
  size,
  avatarType,
  title,
  isHovered = false,
  titleType = "P1",
  titleProps,
  showSelectOnHover,
  person,
  icon,
  onClick,
  subtitle,
  imageUrl,
  contentType,
  color,
  styleDeclaration,
  contentId,
  style,
  avatar,
}) => {
  const { user } = useUser();
  const { brandDetails } = useBranding();
  const shouldShowCheckbox =
    showSelectOnHover?.isSelected || (isHovered && !!showSelectOnHover);

  return (
    <AutoLayout
      id={"AvatarTitleSubtitle-container"}
      direction={"horizontal"}
      alignmentVertical={"center"}
      wrap={"nowrap"}
      flex={1}
      className={css(styleDeclaration)}
      onClick={onClick}
      style={style}
    >
      <div
        className={css(
          styles.avatarContainer,
          size === "32px" ? styles.size32 : styles.size40,
        )}
      >
        {shouldShowCheckbox && showSelectOnHover && (
          <Checkbox
            checked={showSelectOnHover.isSelected}
            onChange={(val, e) => {
              e.stopPropagation();
              e.preventDefault();
              showSelectOnHover.onSelectClick(!showSelectOnHover.isSelected, e);
            }}
          />
        )}
        {!isHovered && !showSelectOnHover?.isSelected && (
          <>
            {avatarType === "Person" && !!person && (
              <PersonAvatar person={person} size={size} color={color} />
            )}
            {avatarType === "Icon" && !!icon && (
              <IconAvatar icon={icon} size={size} color={color} />
            )}
            {avatarType === "Org" && (
              <OrgLogo
                orgId={user?.org?.id || 0}
                logoUrl={
                  brandDetails?.logoUrls?.thumb ||
                  brandDetails?.logoUrls?.original
                }
              />
            )}
            {avatarType === "Content" && !!contentType && (
              <ContentAvatar
                size={size}
                contentType={contentType}
                coverImage={
                  imageUrl
                    ? {
                        imageUrls: { original: imageUrl },
                      }
                    : undefined
                }
                defaultBackgroundHashKey={contentId || ""}
              />
            )}
            {avatar}
          </>
        )}
      </div>
      <AutoLayout
        direction={"vertical"}
        flex={1}
        className={css(styles.textContainer)}
      >
        {typeof title === "string" ? (
          <Text
            type={titleType}
            fontWeight="Medium"
            color={deprecatedTones.black}
            ellipsis
            styleDeclaration={onClick ? styles.hoverUnderline : undefined}
            {...titleProps}
          >
            {title}
          </Text>
        ) : (
          title
        )}

        {!!subtitle && (
          <AutoLayout direction={"horizontal"} alignSelf={"stretch"}>
            <Text
              type={size === "32px" ? "P3" : "P2"}
              color={deprecatedTones.gray8}
            >
              {subtitle?.subtitle1}
            </Text>
            {!!subtitle?.subtitle2 && (
              <div className={css(styles.subtitle2Container)}>
                <CircleDivider />
                <Text
                  type={size === "32px" ? "P3" : "P2"}
                  fontWeight="Regular"
                  color={deprecatedTones.gray8}
                  styleDeclaration={styles.marginLeft8}
                >
                  {subtitle?.subtitle2}
                </Text>
              </div>
            )}
          </AutoLayout>
        )}
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    marginLeft: 12,
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  topMargin4: {
    marginTop: 4,
  },
  topMargin8: {
    marginTop: 4,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  subtitle2Container: {
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
  },
  size32: {
    width: 32,
    height: 32,
  },
  size40: {
    width: 40,
    height: 40,
  },
  hoverUnderline: {
    ":hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
});

export default AvatarTitleSubtitle;
