import React, { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { LocationGroupType } from "@src/types.generated";
import { locationGroupTypeIcon } from "@components/home/constants";

type AvatarType = "role" | "location" | "roleGroup" | LocationGroupType;

type Props = {
  type: AvatarType;
  logoUrl?: string;
};

const IconAvatar: FC<Props> = (props) => {
  return (
    <AutoLayout
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
      height={32}
      width={32}
      style={{
        backgroundColor: deprecatedTones.gray4Alpha,
        borderRadius: 10,
      }}
    >
      {props.logoUrl ? (
        <img
          src={props.logoUrl}
          width={32}
          height={32}
          style={{ borderRadius: 10 }}
          alt="avatar-logo"
        />
      ) : (
        <DeprecatedIcon
          type={AVATAR_TYPE_TO_ICON_TYPE[props.type]}
          color={deprecatedTones.gray7}
        />
      )}
    </AutoLayout>
  );
};

export const AVATAR_TYPE_TO_ICON_TYPE: Record<AvatarType, DeprecatedIconType> =
  {
    role: "id-badge",
    location: "pin",
    roleGroup: "users-circle",
    ...locationGroupTypeIcon,
  };

export default IconAvatar;
