import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveEmployeesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type RemoveEmployeesMutation = { __typename?: 'Mutation', removeEmployees: { __typename?: 'BaseMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null } };


export const RemoveEmployeesDocument = gql`
    mutation RemoveEmployees($ids: [Int!]!) {
  removeEmployees(ids: $ids) {
    success
    error {
      code
    }
  }
}
    `;
export type RemoveEmployeesMutationFn = Apollo.MutationFunction<RemoveEmployeesMutation, RemoveEmployeesMutationVariables>;

/**
 * __useRemoveEmployeesMutation__
 *
 * To run a mutation, you first call `useRemoveEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmployeesMutation, { data, loading, error }] = useRemoveEmployeesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEmployeesMutation, RemoveEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEmployeesMutation, RemoveEmployeesMutationVariables>(RemoveEmployeesDocument, options);
      }
export type RemoveEmployeesMutationHookResult = ReturnType<typeof useRemoveEmployeesMutation>;
export type RemoveEmployeesMutationResult = Apollo.MutationResult<RemoveEmployeesMutation>;
export type RemoveEmployeesMutationOptions = Apollo.BaseMutationOptions<RemoveEmployeesMutation, RemoveEmployeesMutationVariables>;