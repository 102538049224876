import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import CircleDivider from "@src/deprecatedDesignSystem/components/CircleDivider";
import DetailHeaderOverflow from "@src/deprecatedDesignSystem/components/DetailPage/DetailHeaderOverflow";
import DetailHeaderOverflowItem from "@src/deprecatedDesignSystem/components/DetailPage/DetailHeaderOverflowItem";
import { getStringFromChildren } from "@src/deprecatedDesignSystem/components/DetailPage/utils";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Tab from "@src/deprecatedDesignSystem/components/Tab";
import Text from "@ui/text";
import Avatar from "@src/components/ui/Avatar";
import { StyleSheet, css } from "aphrodite";
import {
  Children,
  Fragment,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";
import * as ReactIs from "react-is";

export enum DetailTypes {
  MODULE = "MODULE",
  ROLE = "ROLE",
  LOCATION = "LOCATION",
  RESOURCE = "RESOURCE",
}

type ChildrenProp = {
  children: ReactNode;
};
type ChildrenAndDivRest = ChildrenProp & HTMLAttributes<HTMLDivElement>;
type Person = { id: number; name: string | null | undefined };
type AvatarType = {
  icon?: DeprecatedIconType;
  person?: Person;
  url?: string;
};

function DetailHeader({
  children,
  ...restProps
}: ChildrenAndDivRest): JSX.Element {
  return (
    <AutoLayout
      direction="vertical"
      paddingTop={32}
      paddingBottom={16}
      alignSelf={"stretch"}
      spaceBetweenItems={32}
      {...restProps}
    >
      {children}
    </AutoLayout>
  );
}

DetailHeader.Content = function DetailHeaderContent({
  children,
  ...restProps
}: ChildrenAndDivRest) {
  return (
    <AutoLayout
      width="100%"
      alignmentVertical="center"
      spaceBetweenItems={16}
      spacingMode="space-between"
      {...restProps}
    >
      {children}
    </AutoLayout>
  );
};

DetailHeader.Avatar = function DetailHeaderAvatar({
  icon,
  person,
  url,
}: AvatarType) {
  if (person) {
    return <Avatar size={72} person={person} />;
  }
  if (url) {
    return (
      <img
        src={url}
        alt="avatar"
        width={72}
        height={72}
        className={css(styles.avatar)}
      />
    );
  }
  return (
    <AutoLayout
      width={72}
      height={72}
      styleDeclaration={styles.avatar}
      alignmentVertical="center"
      alignmentHorizontal="center"
    >
      {icon && (
        <DeprecatedIcon
          type={icon}
          color={deprecatedTones.gray8}
          containerSize={54}
          iconSize={54}
        />
      )}
    </AutoLayout>
  );
};

DetailHeader.TitleAndSubtitle = function DetailHeaderTitleAndSubtitle({
  children,
  ...restProps
}: ChildrenAndDivRest) {
  return (
    <AutoLayout
      flex={1}
      direction="vertical"
      spaceBetweenItems={8}
      {...restProps}
    >
      {children}
    </AutoLayout>
  );
};

DetailHeader.TitleRow = function DetailHeaderTitleRow({
  children,
}: ChildrenProp) {
  return (
    <AutoLayout direction="horizontal" spaceBetweenItems={12}>
      {children}
    </AutoLayout>
  );
};

DetailHeader.DetailTypeRow = function DetailHeaderDetailTypeRow({
  children,
}: ChildrenProp) {
  return (
    <AutoLayout
      direction="horizontal"
      alignmentVertical="center"
      alignmentHorizontal="center"
    >
      {children}
    </AutoLayout>
  );
};

DetailHeader.Icon = function DetailHeaderIcon({ children }: ChildrenProp) {
  return (
    <AutoLayout direction="horizontal" spaceBetweenItems={12}>
      {children}
    </AutoLayout>
  );
};

DetailHeader.DetailType = function DetailHeaderDetailType({
  children,
}: ChildrenProp) {
  return (
    <Text
      type="P4"
      fontWeight={"SemiBold"}
      color={deprecatedTones.gray7}
      styleDeclaration={[styles.noUserSelect, styles.marginRight4]}
    >
      {children}
    </Text>
  );
};

DetailHeader.MultiPartTypeHeader = function DetailHeaderMultiPartType({
  children,
}: ChildrenProp) {
  const typeElements = Children.toArray(children).filter(
    (c) => ReactIs.typeOf(c) === ReactIs.Element,
  ) as ReactElement[];
  const typeBlocks = typeElements.filter((c) => c.props.children != null);
  if (typeBlocks.length === 0) return null;
  return (
    <AutoLayout alignmentVertical="center">
      {typeBlocks.map((type, index) => (
        <Fragment key={index}>
          {type}
          {index !== typeBlocks.length - 1 && (
            <CircleDivider styleDeclaration={styles.marginRight4} />
          )}
        </Fragment>
      ))}
    </AutoLayout>
  );
};

DetailHeader.Title = function DetailHeaderTitle({
  children,
  multiline = true,
  loading,
  placeholder,
}: ChildrenProp & {
  multiline?: boolean;
  loading?: boolean;
  placeholder?: string;
}) {
  return (
    <Text
      type="title"
      className="text-foreground"
      multiline={multiline}
      loading={loading}
      skeletonWidth={280}
      style={{
        wordBreak: "break-word",
        overflow: "hidden",
      }}
    >
      {children || (!loading ? placeholder : "")}
    </Text>
  );
};

DetailHeader.Subtitles = function DetailHeaderSubtitles({
  children,
  ...restProps
}: ChildrenAndDivRest) {
  const subtitleElements = Children.toArray(children).filter(
    (c) => ReactIs.typeOf(c) === ReactIs.Element,
  ) as ReactElement[];
  const subtitles = subtitleElements.filter((c) => c.props.children != null);
  if (subtitles.length === 0) return null;
  return (
    <AutoLayout alignmentVertical="center" {...restProps}>
      {subtitles.map((subtitle, index) => (
        <Fragment key={index}>
          {subtitle}
          {index !== subtitles.length - 1 && (
            <CircleDivider styleDeclaration={styles.marginRight8} />
          )}
        </Fragment>
      ))}
    </AutoLayout>
  );
};

DetailHeader.PillRow = function DetailHeaderPillRow({
  children,
}: ChildrenAndDivRest) {
  return (
    <AutoLayout alignmentVertical="center" spaceBetweenItems={4}>
      {children}
    </AutoLayout>
  );
};

DetailHeader.Subtitle = function DetailHeaderSubtitle({
  children,
  loading,
}: ChildrenProp & { loading?: boolean }) {
  return (
    <Text
      type="P2"
      loading={loading}
      color={deprecatedTones.gray7}
      styleDeclaration={[styles.noUserSelect, styles.marginRight8]}
    >
      {children}
    </Text>
  );
};

DetailHeader.Actions = function DetailHeaderActions({
  children,
  ...restProps
}: ChildrenAndDivRest) {
  return (
    <AutoLayout spaceBetweenItems={8} style={{ marginLeft: 20 }} {...restProps}>
      {children}
    </AutoLayout>
  );
};

DetailHeader.Overflow = DetailHeaderOverflow;

DetailHeader.OverflowItem = DetailHeaderOverflowItem;

DetailHeader.Tabs = function DetailHeaderTabs({
  children,
  ...restProps
}: ChildrenAndDivRest) {
  return (
    <div
      style={{
        width: "100%",
      }}
      {...restProps}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 24,
        }}
      >
        {children}
      </div>
      <HorizontalDivider color={deprecatedTones.gray5Alpha} />
    </div>
  );
};

type TabProps<T> = {
  selectedTab: T;
  setSelectedTab: (tab: T) => void;
  tab: T;
  disabled?: boolean;
};

DetailHeader.Tab = function DetailHeaderTab<TabType extends string>({
  selectedTab,
  setSelectedTab,
  tab,
  disabled = false,
  children,
}: ChildrenProp & TabProps<TabType>) {
  const text = useMemo(() => getStringFromChildren(children), [children]);
  return (
    <Tab
      type="Underline"
      height="32px"
      active={selectedTab === tab}
      text={text}
      onClick={() => setSelectedTab(tab)}
      disabled={disabled}
    />
  );
};

export default DetailHeader;

const styles = StyleSheet.create({
  marginRight4: {
    marginRight: 4,
  },
  marginRight8: {
    marginRight: 8,
  },
  noUserSelect: {
    userSelect: "none",
  },
  avatar: {
    borderRadius: 22.5,
    backgroundColor: deprecatedTones.gray4Alpha,
    boxShadow:
      "0px 0px 2.25px 0px rgba(0, 0, 0, 0.15), 0px 2.25px 5.625px 0px rgba(0, 0, 0, 0.05), 0px 9px 45px 0px rgba(0, 0, 0, 0.04)",
  },
});
