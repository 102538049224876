import Button from "@src/deprecatedDesignSystem/components/Button";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { ReactNode, useState } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@src/ui/popover";

type Props = {
  children: ReactNode;
  loading?: boolean;
  closeOnClick?: boolean;
};

export default function DetailHeaderOverflow({
  children,
  loading = false,
  closeOnClick = true,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open && !loading} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="Outline"
          leftIcon="dots-horizontal"
          customIconColor={deprecatedTones.gray10}
          disabled={loading}
          data-testid="detail-overflow-button"
        />
      </PopoverTrigger>
      <PopoverContent
        asChild
        align="end"
        sideOffset={4}
        className="z-[100] flex w-[1] flex-col text-ellipsis border-none bg-background p-1 text-sm font-normal"
      >
        <span onClick={() => closeOnClick && setOpen(false)}>{children}</span>
      </PopoverContent>
    </Popover>
  );
}
