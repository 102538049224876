import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import React, { CSSProperties, ReactElement, useMemo } from "react";
import { deprecatedTones } from "../styles/deprecatedColors";
import AutoLayout from "./AutoLayout";
import HorizontalDivider from "./HorizontalDivider";
import VerticalDivider from "./VerticalDivider";
import { StyleSheet } from "aphrodite";
import Text from "@ui/text";
import Tab from "@src/deprecatedDesignSystem/components/Tab";
import Spacer from "@components/ui/Spacer";
import ScrollableFlexGrow from "@components/ui/flexBox/ScrollableFlexGrow";

export interface Props<K extends string> {
  tabs: K[];
  dividerAfterTab?: K;
  hiddenTabs?: K[];
  render: RenderFns<K>;
  labels: TabCopy<K>;
  disabledFns?: DisabledFns<K>;
  counts?: TabCounts<K>;
  style?: CSSProperties;
  tabsContainerStyle?: CSSProperties;
  queryParamKey?: string;
  defaultTab?: K;
  tabLabelTextProps?: Partial<React.ComponentProps<typeof Text>>;
  contentContainerStyle?: CSSProperties;
}

export default function Tabs<K extends string>({
  tabs,
  dividerAfterTab,
  hiddenTabs,
  labels,
  disabledFns,
  render,
  style,
  tabsContainerStyle,
  queryParamKey = "tab",
  defaultTab,
  contentContainerStyle,
  tabLabelTextProps,
  counts,
}: Props<K>): ReactElement {
  const visibleTabs = useMemo(
    () =>
      !hiddenTabs ? tabs : tabs.filter((tab) => !hiddenTabs.includes(tab)),
    [tabs, hiddenTabs],
  );
  const [selectedTab, setSelectedTab] = useQueryParam(
    queryParamKey,
    withDefault(
      createEnumParam(visibleTabs),
      defaultTab ? defaultTab : visibleTabs[0],
    ),
  );

  const content = render[selectedTab];
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf={"stretch"}
      style={style}
      data-testid={"tabs"}
    >
      <AutoLayout spaceBetweenItems={24} style={tabsContainerStyle}>
        {visibleTabs.map((tab) => (
          <AutoLayout
            key={tab}
            spaceBetweenItems={20}
            data-testid={`tab-${tab}`}
          >
            <Tab
              type="Underline"
              height="40px"
              active={selectedTab === tab}
              text={labels[tab]}
              onClick={() => setSelectedTab(tab, "replaceIn")}
              disabled={disabledFns?.[tab]?.()}
              textProps={tabLabelTextProps}
              count={counts?.[tab]}
            />
            {tab === dividerAfterTab && (
              <VerticalDivider
                color={deprecatedTones.gray5Alpha}
                styleDeclaration={styles.verticalDivider}
              />
            )}
          </AutoLayout>
        ))}
      </AutoLayout>
      <HorizontalDivider color={deprecatedTones.gray5Alpha} />
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        style={{
          overflowX: "hidden",
          ...contentContainerStyle,
        }}
      >
        <ScrollableFlexGrow
          alignSelf={"stretch"}
          flex={1}
          style={{
            overflowY: "auto",
            zIndex: 0,
            position: "relative",
          }}
        >
          <Spacer size={21} />
          {typeof content === "function" ? content() : content}
          <Spacer size={64} />
        </ScrollableFlexGrow>
      </AutoLayout>
    </AutoLayout>
  );
}

const styles = StyleSheet.create({
  verticalDivider: {
    alignSelf: "stretch",
    marginTop: 9,
    marginBottom: 9,
  },
});

export type RenderFns<K extends string> = Record<
  K,
  (() => React.ReactNode) | React.ReactNode
>;

export type TabCopy<K extends string> = Record<K, string>;
export type TabCounts<K extends string> = Partial<Record<K, number>>;

export type DisabledFns<K extends string> = Record<K, () => boolean>;
